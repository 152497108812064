html,body {
	height:100%;
}

#root {
	height:100%;
}
.App {
  text-align: center;
  display:flex;
  height:100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.countries {
	display:flex;
	width:15em;
	flex-direction:column;
	height:100%;
	overflow-y:scroll;
	flex-shrink:0;
	flex-grow:0;
}
div.countries > div {
	flex-grow:1;
	flex-shrink:1;
	flex-basis:1;
	padding:5px;
	border-bottom:1px solid #ddd;
}
div.countries > div:hover {
background-color: lightyellow;
}
div.countries>div.sel{
	background-color:#c5ffec;
}
div.countries>div.loading {
 background-image:url("https://createwebsite.net/wp-content/uploads/2015/09/KeyFrames.gif");
 background-size:100%;
 height:100px;
}

div.countries h1 {
	font-size:100%;
	font-weight:normal;
	text-align:left;
	margin-right:5px;
}
div.countries h2 {
	font-size:80%;
}

div.country_detail {
	flex-grow:10;
	padding-left:20px;
	border-left:1px solid #ddd;
}
